.store {
    width: 100%;
    min-height: auto;
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;
}

.store>h1 {
    padding-left: 120px;
}

.windows {
    width: 100%;
    height: 470px;
    margin-top: 70px;
    /* padding-left: 20px; */
    /* padding-right: 20px;  */
    overflow: hidden;
    display: flex;
    align-items: center;
}
.slider{
    width: 100vw;
}
.slider>div>div{
    min-height: 430px;
    display: flex;
    align-items: center;
    padding: 0;
}
.absoluteBackground {
    width: 100%;
    height: 280px;
    background: #F9F9FC;
    position: absolute;
    left: 0;
    z-index: -1;
    overflow: hidden;
}

.windowProperties {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

.link{
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: -120px;
}

@media screen and (max-width: 1300px) {
    .absoluteBackground{
        height: 220px;
    }
}
@media screen and (max-width: 1065px) {
    .store>h1 {
        font-size: 40px;
        padding-left: 80px;
    }
    .absoluteBackground {
        height: 180px;
    }
}

@media screen and (max-width: 685px) {
    .windows {
        position: inherit;
    }
}
@media screen and (max-width: 420px) {
    .absoluteBackground {
        height: 160px;
    }
    .store>h1 {
        padding-left: 40px;
    }
}