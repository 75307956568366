.container {
    width: 100%;
    min-height: 100vh;
    padding: 60px 0px;
    background: #F8F8F8;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
}

.cards {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.link{
    width: 100%;
    height: 100%;
    position: absolute;
}

@media screen and (max-width: 768px) {
    .container {
        min-height: 800px;
    }
}

@media screen and (max-width: 600px) {
    .container {
        height: 1370px;
    }
}

@media screen and (max-width: 400px) {
    .container {
        height: 1080px;
    }

    .cards {
        justify-content: center;
    }
}