.card {
    width: 350px;
    /* min-width: 350px; */
    height: 350px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    /* margin-left: 10px; */
    margin: auto;
    transition: 150ms ease-in;
}

.card:hover {
    width: 374px;
    height: 430px;
    padding: 10px;
    justify-content: space-evenly;
    background: #FFF;
    box-shadow: 0px 0px 21.751px 0px rgba(14, 30, 60, 0.12);
}

.card:hover .image {
    width: 170px;
    height: 230px;
}

.card:hover>.title {
    color: #F45D11;
}

.card:hover>button {
    display: flex;
}

.card>button:hover {
    background: #DDDDE1;
}

.card:hover>.properties {
    display: flex;
    opacity: 1;
}

.card:hover .windowType {
    top: 0px;
}

.windowType {
    color: #000;
    font-size: 32px;
    font-weight: lighter;
    position: relative;
    top: -65px;
    transition: 1.5s;
}

.image {
    transition: 0.5s;
}

.title {
    color: #868686;
    font-size: 18px;
    line-height: 120.2%;
    font-family: Segoe UI;
    font-weight: 600;
    letter-spacing: 1.44px;
    margin-top: 20px;
}

.card>button {
    display: none;
    justify-content: center;
    align-items: center;
    width: 68px;
    min-height: 28px;
    background: #E9E9ED;
    border: none;
    position: relative;
    left: 32.5%;
    top: 15px;
}

.card>button>span {
    color: #83838A;
    font-size: 14px;
    letter-spacing: 0.84px;
}

.card>button>img {
    display: none;
}

.properties {
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-around;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    opacity: 0;
    transition: 300ms;
}

.properties>span>p:first-child {
    color: #868686;
    font-family: Segoe UI;
}

.properties>span>p:last-child {
    color: #000;
    font-family: Segoe UI;
}

.selectedCard {
    width: 374px;
    height: 430px;
    background: #FFF;
    box-shadow: 0px 0px 21.751px 0px rgba(14, 30, 60, 0.12);
    padding: 15px;
    margin: auto;
    /* margin-left: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.selectedCard>.title {
    color: #F45D11;
}

.selectedCard>button {
    width: 68px;
    height: 28px;
    background: #E9E9ED;
    border: none;
    position: relative;
    left: 32.5%;
    top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectedCard>button>span {
    color: #83838A;
    font-size: 14px;
    letter-spacing: 0.84px;
}

.selectedCard>button:hover {
    background: #DDDDE1;
}

.selectedCard>button>img {
    display: none;
}

.selectedCard>.properties {
    opacity: 1;
}

.selectedCard .windowType {
    top: 0px;
    color: #F45D11;
}

.selectedCard .image {
    width: 170px;
    height: 230px;
}


@media screen and (max-width: 1800px) {
    .card {
        transform: scale(0.8);
    }

    .selectedCard {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 1250px) {

    .card,
    .selectedCard {
        transform: scale(0.7);
    }
}

@media screen and (max-width: 770px) {
    .card button,
    .selectedCard button {
        width: 35px !important;
        min-height: 35px !important;
    }

    .card button>span,
    .selectedCard button>span {
        display: none;
    }

    .card button>img,
    .selectedCard button>img {
        display: block !important;
    }
}

@media screen and (max-width: 580px) {

    .card,
    .selectedCard {
        transform: scale(0.5);
    }
}

@media screen and (max-width: 500px) {
    .card {
        transform: scale(0.7);
        min-height: 420px;
        margin-top: 15%;
    }

    .card:hover {
        min-width: 196px;
        height: 484px !important;
    }

    .selectedCard {
        transform: scale(0.7);
        min-width: 196px;
        min-height: 484px !important;
    }

    .properties {
        flex-wrap: wrap;
    }

    .properties>span {
        width: 40%;
        margin-top: 20px;
    }
}
@media screen and (max-width: 360px) {
.card, .selectedCard{
    transform: scale(0.55);
}
}