.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 125px;
    background: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    z-index: 100;
}

a {
    text-decoration: none;
}
a>h1{
    color: #8F9093;
    font-family: "Arm Hmks Bebas Neue Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.72px;
}
a:hover>h1 {
    color: #000;
}

.selected {
    color: #000;
}

.menu {
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pages {
    width: 100%;
    min-width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 5%;
    z-index: 50;
}

.extras {
    width: 45%;
    display: flex;
    justify-content: flex-end;
}

.extras>div {
    width: 70%;
    display: flex;
    justify-content: space-evenly;
}

.extras>div>div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.phoneNumber {
    min-width: 130px;
    color: #565962;
    font-family: "Arm Hmks Bebas Neue Regular";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.72px;
}

.contactButton {
    width: 120px;
    height: 50px;
    background: #F45D11;
    border: none;
    color: white;
    margin-left: 10px;
    font-size: 20px;
}

.contactButton:hover {
    background: #CD4D0C;
}

.languages {
    color: #8F9093;
}

.languages>p {
    cursor: pointer;
}

.languages>p:last-child {
    margin-left: 10px;
}

.mobileHeader {
    width: 50%;
    display: none;
    justify-content: space-evenly;
}

.mobileHeader img {
    cursor: pointer;
}

.mobileMenu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 448px;
    background: white;
    padding: 40px 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.closeIcon {
    position: absolute;
    right: 22px;
    top: 28px;
    cursor: pointer;
}

.mobilePages {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.mobileMenu .languages {
    display: flex;
}

.mobileMenu .contactButton {
    margin: 0;
}
@media screen and (max-width: 1060px) {
    .header {
        height: 88px;
    }
    .menu {
        width: 55%;
    }
    .extras {
        width: 40%;
        margin-left: -5%;
    }
    .companyLogo {
        width: 124px;
        height: 45px;
    }
    .header h1,p{
        font-size: 14px;
    }
    .phoneNumber {
        min-width: 110px;
    }
    .contactButton {
        width: 80px;
        height: 40px;
        font-size: 16px;
        margin-left: -20px;
    }
    .languages{
        margin-left: 12.5px;
    }
    .languages>p:last-child {
        margin-left: 5px;
    }
}
@media screen and (max-width: 750px) {
    .contactButton {
        width: 70px;
        height: 35px;
        font-size: 15px;
        margin-left: -10px;
    }
    .phoneNumber {
        font-size: 12px;
        min-width: 100px;
        margin-left: 10px;
        position: relative;
        left: 10px;
    }
}
@media screen and (max-width: 685px) {
    .menu {
        width: 45%;
    }
    .pages {
        display: none;
    }

    .extras {
        display: none;
    }

    .mobileHeader {
        display: flex;
    }
}