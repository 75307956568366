.card {
    width: 460px;
    height: 285px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(103, 103, 103, 0.20);
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 10px;
}

.card>img {
    transform: scale(0.8);
}

.title {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.2px;
}

.description {
    width: 380px;
    color: #575757;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media  screen and (max-width: 768px) {
    .card{
        width: 224px;
        height: 210px;
        margin-top: 10px;
    }
    .card>img{
        width: 60px;
        height: 60px;
    }
    .title {
        width: 90%;
        height: 22px;
        font-size: 16px;
        position: relative;
        top: -5px;
    }
    .description {
        width: 90%;
        height: 50px;
        font-size: 12px;
    }
}

@media  screen and (max-width: 600px) {
    .card{
        width: 380px;
        height: 190px;
        margin-top: 10px;
    }
    .card>img{
        width: 73px;
        height: 73px;
    }
    .title {
        width: 90%;
        height: 22px;
        font-size: 21px;
        position: relative;
        top: -5px;
    }
    .description {
        width: 90%;
        height: 50px;
        font-size: 15px;
        position: relative;
        top: -5px;
    }
}
@media  screen and (max-width: 500px) {
    .description {
        width: 90%;
        font-size: 13px;
        top: -5px;
    }
}
@media  screen and (max-width: 400px) {
    .card{
        width: 85vw;
        height: 160px;
        margin-top: 10px;
    }
    .card>img{
        width: 44px;
        height: 44px;
    }
    .title {
        width: 90%;
        height: 22px;
        font-size: 15px;
        position: relative;
        top: -5px;
    }
    .description {
        width: 90%;
        height: 32px;
        font-size: 12px;
        top: -15px;
    }
}
@media  screen and (max-width: 400px) {
    .description{
        font-size: 11px;
    }
}