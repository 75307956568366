.card {
    width: 300px !important;
    height: 400px !important;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: start !important;
    align-items: center;
    opacity: 0;
    animation: smoothAppear 1 1s forwards;
}

@keyframes smoothAppear {
    100% {
        opacity: 1;
        height: 565px;
    }
}

.properties {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.properties p {
    width: 100px;
    height: 27.24px;
    color: #575757;
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 400;
}

.title {
    color: #000;
    font-family: Segoe UI;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.34px;
}

.price {
    color: #F45D11;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.8px;
}

.price>span {
    color: #000;
    font-family: Segoe UI;
    font-size: 14px;
    letter-spacing: 0.48px;
}

@media screen and (max-width: 1540px) {
    .card {
        width: 180px;
        transform: scale(0.8);
        height: 70%;
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 1340px) {
    .card {
        transform: scale(0.65);
    }
}

@media screen and (max-width: 1065px) {
    .card {
        width: 240px;
        justify-content: center;
        margin-top: 0;
    }
}

@media screen and (max-width: 740px) {
    .card {
        width: 280px;
        transform: scale(0.7);
        margin-top: 10px;
    }
}

@media screen and (max-width: 560px) {
    .card {
        width: 60%;
        min-height: auto;
        margin-top: 30px;
    }
}

@media screen and (max-width: 390px) {
    .card {
        transform: scale(0.8);
        margin-top: 30px;
    }
}

@media screen and (max-width: 310px) {
    .card {
        transform: scale(0.7);
        margin-top: 10px;
    }

    .title {
        font-size: 13.5px;
    }

    .price {
        font-size: 20px;
    }

    .properties p {
        font-size: 12px;
    }
}