.aboutContainer{
  width: 100%;
  height: 630px;
  overflow: hidden;
  position: relative;
}
.about {
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  display: flex !important;
}
.about>div{
  width: 50%;
  height: 100%;
}
.aboutText {
  padding: 72px 120px;
}

.aboutText>h1{
  line-height: 72px;
  margin-bottom: 19px;
}

.aboutText>p {
  width: 95%;
  font-weight: 400;
  line-height: 2;
  font-family: 'Segoe UI';
  font-size: 13px;
}


.aboutImageContainer {
  height: 630px !important;
  display: flex;
}
.aboutImageContainer img{
  object-position: center;
}

.link{
  width: 100%;
  height: 100%;
  cursor: default;
  color: #000;
}

@media screen and (max-width: 1553px) {
  .aboutText>h1 {
    font-size: 55px;
  }
  .aboutText>p {
    font-size: 12.25px;
  }
}

@media screen and (max-width: 1350px) {
  .aboutContainer{
    height: auto;
  }
  .about {
    height: 450px;
  }
  .about>div{
    width: 50%;
  }

  .aboutText{
    height: 400px;
    padding: 30px;
    display: flex; 
    justify-content: center;
    flex-direction: column;
 }
  .aboutText>h1 {
    width: 100%;
    font-size: 30px;
    letter-spacing: 1.04px;
  }
  .aboutText>p {
    width: 100%;
    font-size: 11px;
    line-height: 160%;
  }
}

@media screen and (max-width: 910px) {
  .about {
    height: 720px;
    flex-direction: column-reverse;
  }
  .about>div{
    width: 100%;
  }
  .aboutImageContainer{
    height: 400px !important;
  }
  .aboutText{
    justify-content: space-evenly;
 }
  .aboutText>h1 {
    font-size: 38px;
    margin-bottom: 0;
  }

  .aboutText>p {
    font-size: 13.5px;
  }
}

@media screen and (max-width: 600px) {
  .about {
    height: 715px;
  }
  .aboutText{
    padding: 37px;
  }
  .aboutText>h1 {
    font-size: 26px;
    letter-spacing: 1.04px;
    font-weight: 400;
    position: relative;
    top: -15px;
  }
  .aboutText>p {
    font-size: 11.5px;
  }
}
@media screen and (max-width: 500px) {
.about{
  height: 800px;
}
.aboutImageContainer{
  max-height: 350px !important;
}
}
@media screen and (max-width: 400px) {
  .aboutText{
    height: 301px;
    padding: 40px;
  }
  .aboutText>h1{
    line-height: 36px;
  }
  .aboutImageContainer{
    height: 250px !important;
  }
  .aboutImageContainer{
    min-height: 280px !important;
  }
}
@media screen and (max-width: 360px) {
  .aboutText>h1{
    line-height: 28px;
  }
  .aboutText>p{
    line-height: 1.5;
    font-size: 10px;
  }
 
}