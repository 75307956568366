* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arm Hmks Bebas Neue Regular";
}

html {
  scroll-behavior: smooth;
  scroll-padding: 125px;
}

button {
  cursor: pointer;
}

.title {
  color: #000;
  /* font-family: Arm Hmk's Bebas Neue; */
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.4px;
}

.toDownLInk {
  cursor: default;
  color: unset;
}

.arrows {
  width: 90%;
  height: calc(100% + 62.5px);
  position: absolute;
  left: 0;
  right: 0;
  margin-left: 6.25%;
  margin-top: -125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow {
  width: 40px;
  height: 40px;
  border: solid 10px white;
  border-left: none;
  border-top: none;
  opacity: 0.8;
  transform: rotateZ(135deg);
  cursor: pointer;
  z-index: 10;
}

.arrow:hover {
  opacity: 1;
}

.arrow:active {
  border-color: black;
}

.arrow2 {
  transform: rotateZ(-45deg);
}

#about>.arrows,
#store .arrows {
  width: 95%;
  height: 630px;
  margin: auto;
  margin-top: 0;
}

#about .arrow,
#store .arrow {
  border-color: black;
}

#about .arrow:active {
  border-color: white;
}

#store .arrows {
  height: 25%;
}

#home .slick-dots {
  width: auto;
  margin-left: 0;
  top: calc(100vh - 160px);
  left: 7%;
}

#home .slick-dots li {
  width: 20px;
  min-height: 20px;
  background: white;
  clip-path: polygon(25% 25%, 50% 10%, 75% 25%, 75% 75%, 25% 75%);
  transform: scale(2);
  opacity: 0.5;
}

#home ul .slick-active {
  opacity: 0.8;
}

#home .slick-dots button::before {
  content: "";
}

@media screen and (max-width: 1060px) {
  html {
    scroll-padding: 88px;
  }

  #home .slick-dots {
    top: calc(100vh - 123px);
  }
}

@media screen and (max-width: 1024px) {
  .arrows {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 45px;
    letter-spacing: 1.04px;
  }
}

@media only screen and (max-width: 680px) {
  #home .slick-dots {
    top: calc(70vh - 155px);
  }

  #home .slick-dots li {
    transform: scale(1.3);
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 32px;
    letter-spacing: 1.04px;
  }
}

@media only screen and (max-width: 400px) {
  .title {
    font-size: 26px;
    letter-spacing: 1.04px;
  }
  #home .slick-dots {
    left: 9%;
  }
  #home .slick-dots li {
    transform: scale(1.1);
    margin-left: -10px;
  }
}