.sliderContainer {
    width: 100%;
    height: calc(100vh - 125px);
    margin-top: 125px;
    overflow: hidden;
}

.sliderDiv {
    width: 100%;
    height: calc(100vh - 125px);
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    padding-bottom: 50px;
}

.sliderImage {
    width: 100vw;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    z-index: -1;
}

.sliderContent {
    width: 90%;
    height: 20%;
    color: #FFF;
    font-style: normal;
    line-height: normal;
    margin-left: 7%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.title {
    font-size: 60px;
    font-weight: 400;
    letter-spacing: 2.4px;
}

.description {
    font-family: Segoe UI;
    font-size: 24px;
    font-weight: 700;
}
@media screen and (max-width: 1400px) {
    .sliderContent {
        height: 25%;
    }

    .title {
        font-size: 40px;
    }
}

@media screen and (max-width: 1060px) {
    .sliderContainer {
        height: calc(100vh - 88px);
        margin-top: 88px;
    }

    .sliderDiv {
        height: calc(100vh - 88px);
    }

    .sliderContent {
        height: 20%;
    }

    .title {
        font-size: 35px;
    }

    .description {
        font-size: 22px;
    }
}

@media screen and (max-width: 880px) {
    .title {
        font-size: 30px;
    }

    .description {
        font-size: 18px;
    }
    
}

@media screen and (max-width: 680px) {
    .sliderContainer {
        height: calc(70vh - 125px);
    }

    .sliderDiv {
        height: calc(70vh - 125px);
    }

    .title {
        width: 90%;
        font-size: 26px;
        letter-spacing: 1.04px;
    }

    .description {
        width: 90%;
        font-size: 12px;
        line-height: 18px;
    }
}

@media screen and (max-width: 480px) {
    .sliderContent {
        height: 25%;
        margin-bottom: 0%;
    }

    .title {
        width: 98%;
        font-size: 20px;
        letter-spacing: 1.04px;
    }

    .description {
        width: 98%;
        font-size: 12px;
        line-height: 18px;
    }
}