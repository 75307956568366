.contact {
  width: 100%;
  height: 617px;
  display: flex;
}

.contact>div {
  width: 50%;
  height: 100%;
}

.contact>div:first-child {
  display: flex;
}

.socialMedia {
  width: 50%;
  padding: 10% 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.socialMedia>div>div {
  margin-top: 26px;
}

.socialMedia svg:hover>path {
  fill: #000;
}

.socialMedia>div p {
  font-size: 18px;
  margin-top: 10px;
  font-family: "Segoe UI";
}

.footer {
  margin-top: 50px !important;
}

.footer>p>span {
  color: #787A83;
  font-size: 16px;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 400;
  line-height: 171.429%;
}

.footer>p>span:last-child {
  margin-left: 10px;
}

.map {
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

iframe {
  border: none;
}


.contactForm {
  width: 100%;
  height: 100%;
  background-color: #EFEFF0;
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 8px;
  margin-top: 20px;
}

input,
textarea {
  color: #575757;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 50%;
  min-height: 50px;
  background-color: #E3E3E3;
  border: none;
  outline: none;
  padding: 18px;
}

textarea {
  max-height: 225px;
  height: 124px;
  resize: vertical;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input:hover, textarea:hover {
  background: #D8D8D8;
}

input:focus, textarea:focus {
  background: #E3E3E3;
  color: #000;
}
input:valid, textarea:valid{
  color: #000;
}
input:invalid, textarea:invalid{
  border: 1px solid #E81B27;
  background: #E3E3E3;
}
form > button {
  width: 199px;
  height: 50px;
  background-color: #F45D11;
  border: none;
  margin-top: 24px;
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
}
form>button:hover{
  background: #CD4D0C;
}
form span{
color: #E81B27;
font-family: Segoe UI;
font-size: 12px;
font-weight: 400;
}


@media screen and (max-width: 1500px) {
  input,textarea {
    width: 75%;
  }
  .contactForm>div>h1 {
    font-size: 45px;
  }
}

@media screen and (max-width: 1080px) {
  .contact {
    flex-direction: column-reverse;
    height: auto;
  }

  .contact>div:first-child {
    width: 100%;
    flex-direction: column-reverse;
  }
  .contactForm>div>h1 {
    font-size: 40px;
  }
  input,textarea {
    width: 90%;
  }
  .contactForm {
    width: 100% !important;
    padding: 45px;
    height: 600px !important;
  }
  .map{
    width: 100%;
    height: 420px;
  }
  .socialMedia {
    width: 100%;
    height: 550px;

  }
}

@media only screen and (max-width: 680px) {
  .contactForm {
    width: 100% !important;
    height: 430px;
    padding: 40px;
  }

  .contactForm>div {
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .contactForm>div>h1 {
    margin-bottom: 20px;
  }

  form {
    margin-top: 0;
  }
  
  input,
  textarea {
    width: 100%;
  }
  .socialMedia svg{
    width: 38px;
    height: 38px;
  }
}

@media only screen and (max-width: 400px) {
  .socialMedia {
    height: 322px;
  }
}